import {Button} from "@mui/material";
import React from "react";


export default function GSButton(props) {

    const { icon, text ,onClick,useRef} = props


    return <Button ref={useRef} color="primary" variant="contained" onClick={onClick}  >
        <div style={{display: 'flex', alignItems: 'center',height:'25px'}}>
            {icon}
            <p style={{color: "#FFFFFF", fontWeight: "bold"}} >{text}</p>
        </div>
    </Button>
}