import React from 'react'
import {useForm} from "react-hook-form";
import axios from "axios";
import {APIURL} from "../../Views/Routes";
import {Paper, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {Add, Close} from "@mui/icons-material";
import {createPortal} from "react-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";


export default function ErrorDialog(props) {
    const {error, setError, errorMessage, setErrorMessage, title} = props

    const handleClose = () => {
        setError(false)
        setErrorMessage("")
    };

    const portalElement = document.getElementById('portal');
    const component = error ? (
        <div className="portal-overlay" style={{backdropFilter: " blur(5px)"}}>
            <Paper className="confirm-dialog" sx={{
                borderRadius: 10,
                border: 1,
                borderColor: "primary.main"
            }}>
                <Grid container justifyContent="center">
                    {/*<DialogTitle id="alert-dialog-title">{!title ? "Es ist ein Fehler aufgetreten" : title}</DialogTitle>*/}
                    <DialogContent style={{width:"100%"}}>
                        <DialogContentText  id="alert-dialog-description" align="center">
                            {errorMessage === "" ?
                                "Da ist etwas schief gegangen! Bitte probieren Sie es erneut!" :
                                errorMessage
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Grid>
            </Paper>
        </div>
    ) : null;

    return createPortal(component, portalElement);

}
