import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyCnuYi7wBmsms8hwczsiep9szxKCfaDkPo",
    authDomain: "gspush-952ef.firebaseapp.com",
    projectId: "gspush-952ef",
    storageBucket: "gspush-952ef.appspot.com",
    messagingSenderId: "399426487059",
    appId: "1:399426487059:web:70942d19bf6d38baaa90e6",
    measurementId: "G-NE21F59X4Z"
};

let firebaseApp;
export let messaging;

export const tryregisterMessaging = () => {

    if ('serviceWorker' in navigator) {
        firebaseApp = initializeApp(firebaseConfig);
        messaging = getMessaging(firebaseApp);
    }
}


export const fetchToken = () => {
    return getToken(messaging, {vapidKey: 'BLGvAztrbJ7H86qydjO3NO9sdm0fUnfgVuf8RxhhhZ_Qim9crvBUSg65lvmf6NWBZ3Egk0ZswcAVBLKoNhVAUuI'})
}



