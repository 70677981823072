import React from 'react';
import {createPortal} from 'react-dom';
import useConfirm from './useConfirm';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import UserNamePasswordLogin from "../Checkout/UsernamePasswordLogin";
import {getSession} from "../Auth/AuthHandler";
import {Paper, useTheme} from "@mui/material";
import ConfirmButtonDialog from "./ConfirmButton";

const ConfirmDialog = (props) => {

    const {expired, setExpired} = props
    const {onConfirm, onCancel, confirmState} = useConfirm();
    const theme = useTheme()
    const handleClose = () => {
        setExpired(false)
        setExpired(false)
        onConfirm()
    };

    const portalElement = document.getElementById('portal');


    const component = confirmState.show ? (

        <div className="portal-overlay">
            <Paper className="confirm-dialog" sx={{width:"90%",maxWidth:400}}>
                {confirmState.text?.action === "confirm" ?
                    <ConfirmButtonDialog
                        title={confirmState.text.title}
                        text={confirmState.text.text}
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                    /> :
                    <UserNamePasswordLogin nextStep={handleClose}
                                           headerText={"Sind Sie noch da?"}
                                           session={true}/>
                }
            </Paper>

        </div>
    ) : null;


    return createPortal(component, portalElement);
};
export default ConfirmDialog;