import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import {AppBar, Fab, FormControlLabel, Grid, Hidden, Switch, Toolbar, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import logBall from "../../img/Kugelweb.svg";
import {Link} from "react-router-dom";
import {getSession, logout} from "../Auth/AuthHandler";
import IconButton from "@mui/material/IconButton";
import {AccountCircle, NotificationsActive, NotificationsOff, ViewQuilt} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@mui/icons-material/Settings";
import GSButton from "./GSButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {useTheme} from "@mui/styles";
import LoginIcon from '@mui/icons-material/Login';
// const PREFIX = 'DocumentationRootFrame';
//
// const classes = {
//     title: `${PREFIX}-title`,
//     headline: `${PREFIX}-headline`,
//     appbar: `${PREFIX}-appbar`
// };
//
// const Root = styled('div')((
//     {
//         theme
//     }
// ) => ({
//     [`& .${classes.title}`]: {
//         // marginRight: 10,
//         // color: "white"
//         color: theme.palette.primary.main,
//         fontWeight: "bold"
//
//     },
//
//     [`& .${classes.headline}`]: {
//         color: theme.palette.primary.main,
//         textTransform: "none"
//     },
//
//     [`& .${classes.appbar}`]: {
//         background: theme.palette.background.default,
//
//         // background: "linear-gradient(90deg, rgba(71,130,198,1) 5%, rgba(21,101,192,1) 90%)",
//         // borderBottom:"1px solid grey"
//     }
// }));

export default function DocumentationRootframe(props) {

    const {content, loggedIn, darkMode, setDarkMode} = props

    const [account, setAccount] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme()
    const [notificationEnabled, setNotificationEnabled] = useState(false)


    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
        setAccount(true)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setAccount(false)
    };
    const handleLogout = (e) => {
        logout()
        setAccount(false)
    }

    useEffect(() => {
        if (notificationEnabled)
            // eslint-disable-next-line no-undef
            PushService.requestPermission()
                .then(x => console.log("resolved promise:"+x))


    }, [notificationEnabled])


    const handleToogleNotifications = () => {
        setNotificationEnabled(!notificationEnabled)
    }

    return (
        <Grid item xs={12}>
            <AppBar position="static" elevation={0}>
                <Toolbar >
                    <Grid container justifyContent="flex-start">
                        <Link to={"/start"} style={{color: 'inherit', textDecoration: 'inherit'}}>
                            <Button >
                                <Hidden smDown>
                                    <Typography sx={{
                                        color: "light.main"
                                    }} variant="h5">
                                        GSS Vision
                                    </Typography>
                                </Hidden>
                                <Hidden smUp>
                                    <Typography sx={{
                                        color: "light.main"
                                    }} variant="subtitle1">
                                        GSS Vision
                                    </Typography>
                                </Hidden>

                            </Button>
                        </Link>
                    </Grid>

                    <Grid container justifyContent="flex-end">
                        {/*<Button>*/}
                        {/*    <Link to={"/learn"} style={{color: 'inherit', textDecoration: 'inherit'}}>*/}
                        {/*        <Typography variant="body1" className={classes.title}>*/}
                        {/*            Learn*/}
                        {/*        </Typography>*/}
                        {/*    </Link>*/}
                        {/*</Button>*/}
                        {/*<Button>*/}
                        {/*<Link to={"/linkmanager"} style={{color: 'inherit', textDecoration: 'inherit'}}>*/}
                        {/*    <Typography variant="body1" className={classes.title}>*/}
                        {/*        */}
                        {/*    </Typography>*/}
                        {/*</Link>*/}
                        {/*</Button>*/}
                        {/*<Button>*/}
                        {/*    <Typography variant="body1" className={classes.title}>*/}
                        {/*        FAQ*/}
                        {/*    </Typography>*/}
                        {/*</Button>*/}

                        {/*<Fab style={{marginRight:5,marginTop:-2}} size="small" color="primary" onClick={handleToogleNotifications}>*/}

                        {/*    {notificationEnabled ?*/}
                        {/*        <NotificationsActive/> :*/}
                        {/*        <NotificationsOff/>*/}
                        {/*    }*/}

                        {/*</Fab>*/}


                        <div>

                            {getSession() ?
                                <div>
                                    {/*<Link to="/work">*/}

                                    {/*<Button variant="contained" color="primary">*/}
                                    {/*    Start*/}
                                    {/*</Button>*/}
                                    {/*</Link>*/}

                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color={"primary"}
                                        size="large">
                                        <AccountCircle className="svg_icons"/>
                                    </IconButton>

                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={account}
                                        onClose={handleClose}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: "column",
                                            alignContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <h3>Informationen:</h3>
                                            <MenuItem> Benutzer: {getSession().username}</MenuItem>
                                            <Divider/>
                                            <MenuItem>
                                                <FormControlLabel
                                                    control={<Switch checked={darkMode} onChange={e => {
                                                        setDarkMode(!darkMode)
                                                    }} name="theme"/>}
                                                    label="Dark Mode"
                                                />
                                            </MenuItem>
                                            <Divider/>
                                            <MenuItem onClick={handleClose}> {<SettingsIcon/>}
                                                <Link style={{color: 'inherit', textDecoration: 'inherit'}}
                                                      to="/user">Profil bearbeiten</Link>
                                            </MenuItem>

                                            <MenuItem>
                                                <Link to={"/"} style={{color: 'inherit', textDecoration: 'inherit'}}>
                                                    <GSButton onClick={handleLogout} icon={<ExitToAppIcon/>}
                                                              text="Logout"/>
                                                </Link>
                                            </MenuItem>
                                        </div>
                                    </Menu>
                                </div> :
                                <div>
                                    <Link to={"/login"} style={{color: 'inherit', textDecoration: 'inherit'}}>
                                        <Hidden smUp>
                                            <Button variant="contained" color="primary">
                                                <LockOpenIcon/>
                                            </Button>
                                        </Hidden>
                                        <Hidden smDown>
                                            <Button color="secondary" variant="contained" startIcon={<LockOpenIcon/>}>
                                                Bitte einloggen
                                            </Button>
                                        </Hidden>
                                    </Link>


                                </div>
                            }
                        </div>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Grid>
                <Grid item xs={12} style={{overflow: "hidden"}}>
                    {props.children}
                </Grid>
            </Grid>


        </Grid>
    );

}