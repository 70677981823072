export const ResponseCode = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    ERROR_NOT_SUPPORTED: "ERROR_NOT_SUPPORTED",
    ERROR_1002: "error1002", // Vorgangsart null
    ERROR_1003: "error1003", // Vorgangsart leer
    ERROR_1004: "error1004", // Vorgangsart nicht bekannt
    ERROR_1005: "error1005", // Abrechnungsart null
    ERROR_1006: "error1006", // Abrechnungsart leer
    ERROR_1007: "error1007", // Abrechnungmonat/-jahr fehlt
    ERROR_STATUS: "ERROR_STATUS", // Fehlerhafter Status
    ERROR_1009: "error1009", // Vertrag 'von' null
    ERROR_1010: "error1010", // Vertrag 'bis' null
    ERROR_1011: "error1011", // Vertrag 'bis' ist vor Vertrag 'von'
    ERROR_1012: "error1012", // Kundennummer null
    ERROR_1013: "error1013", // Kundennummer leer
    ERROR_1014: "error1014", // Rechnungsadresse null
    ERROR_1015: "error1015", // Lieferadresse null
    ERROR_1016: "error1016", // Vertriebsbereich null
    ERROR_1017: "error1017", // Vertriebsbereich leer
    ERROR_1018: "error1018", // Lieferart null
    ERROR_1019: "error1019", // Lieferart leer
    ERROR_1020: "error1020", // Fahrer null
    ERROR_1021: "error1021", // Fahrer leer
    ERROR_1022: "error1022", // Tour null
    ERROR_1023: "error1023", // Tour leer
    ERROR_1024: "error1024", // Zahlungsart null
    ERROR_1025: "error1025", // Zahlungsart leer
    ERROR_1026: "error1026", // Artikelnummer null
    ERROR_1027: "error1027", // Artikelnummer leer
    ERROR_1028: "error1028", // Artikelbezeichnung null
    ERROR_1029: "error1029", // Artikelbezeichnung leer
    ERROR_1030: "error1030", // Lieferdatum 'von' null
    ERROR_1031: "error1031", // Lieferdatum 'bis' null
    ERROR_1032: "error1032", // Bestellmenge null
    ERROR_1033: "error1033", // Bestellmenge 0.0
    ERROR_1034: "error1034", // Bestelleinheit null
    ERROR_1035: "error1035", // Bestelleinheit leer
    ERROR_1036: "error1036", // Auftragsmenge null
    ERROR_1037: "error1037", // Auftragsmenge 0.0
    ERROR_1038: "error1038", // Auftragseinheit null
    ERROR_1039: "error1039", // Auftragseinheit leer
    ERROR_1040: "error1040", // Einzelpreis null
    ERROR_1041: "error1041", // Einzelpreis 0.0
    ERROR_1042: "error1042", // Gesamtpreis null
    ERROR_1043: "error1043", // Gesamtpreis 0.0
    ERROR_1044: "error1044", // Mehrwertsteuersatz null
    ERROR_1045: "error1045", // Mehrwertsteuersatz 0.0
    ERROR_1046: "error1046", // Bestandsfuehrung null
    ERROR_1047: "error1047", // Bestandsfuehrung falscher Wert
    ERROR_1048: "error1048", // Preistyp null
    ERROR_1049: "error1049", // Preistyp leer
    ERROR_1050: "error1050", // Reservierung null
    ERROR_1051: "error1051", // Reservierung leer
    ERROR_1052: "error1052", // Charge null
    ERROR_1053: "error1053", // Charge leer
    ERROR_Entity_Not_Compatible: "error1054", // Keine Positionen vorhanden
    ERROR_1055: "error1055", // Mandant null
    ERROR_1056: "error1056", // Mandant fehlt
    ANZAHL_POSITIONEN_UNGLEICH_1: "ANZAHL_POSITIONEN_UNGLEICH_1",
    ERROR_1058: "error1058",
    ERROR_1059: "error1059", // Adressbereich null
    ERROR_1060: "error1060", // Adressbereich fehlt
    ERROR_1061: "error1061", // Adressindex null
    ERROR_1062: "error1062", // Adressindex fehlt
    ERROR_1063: "error1063", // Name null
    ERROR_1064: "error1064", // Name leer
    ERROR_1065: "error1065", // Strasse null
    ERROR_1066: "error1066", // Strasse leer
    ERROR_1067: "error1067", // Land null
    ERROR_1068: "error1068", // Land leer
    ERROR_1069: "error1069", // Plz null
    ERROR_1070: "error1070", // Plz leer
    ERROR_1071: "error1071", // Ort null
    ERROR_1072: "error1072", // Ort leer
    ERROR_TRANSACTION_FAILED: "ERROR_TRANSACTION_FAILED", // SQL Transaction failed
    ERROR_1074: "error1074",
    ERROR_SQL_ROW_EXISTS: "ERROR_SQL_ROW_EXISTS", // Row already exists
    ERROR_SQL_TRANSACTION_CREATE_FAILED: "ERROR_SQL_TRANSACTION_CREATE_FAILED",
    ERROR_1077: "error1077", // Row must exist
    ERROR_ROW_DOESNT_EXISTS: "ERROR_ROW_DOESNT_EXISTS", // Row doesn't exists
    ERROR_1079: "error1079", // Lieferschein kann nicht abgerechnet werden
    ERROR_SQL_TRANSACTION_UPDATE_FAILED: "ERROR_SQL_TRANSACTION_UPDATE_FAILED", // SQL transactionupdate: Lieferschein update gescheitert
    ERROR_SQL_TRANSACTION_READ_FAILED: "ERROR_SQL_TRANSACTION_READ_FAILED", // read: Lieferschein nicht vorhanden
    ERROR_1082: "error1082", // Position nicht vorhanden
    ERROR_1083: "error1083", // Position mehrfach vorhanden
    ERROR_1084: "error1084", // Position bereits vorhanden
    ERROR_1085: "error1085", // Datensatz nicht vorhanden
    ERROR_1086: "error1086", // Datensatz mehrfach vorhanden
    ERROR_1087: "error1087", // Datensatz darf nur einmal vorhanden sein
    ERROR_MORE_THAN_ONE_ROW_EXISTS: "ERROR_MORE_THAN_ONE_ROW_EXISTS",
    ERROR_1089: "error1089",
    ERROR_1090: "error1090",
    ERROR_1091: "error1091",
    ERROR_1092: "error1092",
    ERROR_1093: "error1093",
    ERROR_1094: "error1094",
    ERROR_1095: "error1095",
    ERROR_1096: "error1096",
    ERROR_1097: "error1097",
    ERROR_1098: "error1098",
    ERROR_1099: "error1099",
    ERROR_1101: "error1101", // Vorgangsnummer fehlerhaft
    ERROR_1102: "error1102",
    ERROR_1103: "error1103", // Vorgangsjahr fehlerhaft
    ERROR_1104: "error1104",
    ERROR_1105: "error1105", // Vorgangsposition fehlerhaft
    ERROR_1106: "error1106",
    ERROR_1107: "error1107",
    ERROR_1108: "error1108", // Abrechnungsart fehlerhaft
    ERROR_1109: "error1109", // 0:
    ERROR_1110: "error1110", // 1:
    ERROR_1111: "error1111", // 2:
    ERROR_1112: "error1112", // 3:
    ERROR_1113: "error1113", // 4:
    ERROR_1114: "error1114", // 5:
    ERROR_INVOICE_AGGREGATION: "error1115", // 6: Rechnungszusammenfassung fehlerhaft :Einzelrechnung/Lieferschein, Sammelrechnung/Auftrag/Kunden
    ERROR_1116: "error1116", // 7:
    ERROR_1117: "error1117", // 8:
    ERROR_1118: "error1118", // 9:
    ERROR_1119: "error1119", // 10:
    ERROR_INVOICE_PERIODIC: "error1120", // 11: Rechnungslegung fehlerhaft :einmalig/periodisch
    ERROR_1121: "error1121", // 12:
    ERROR_1122: "error1122", // 13:
    ERROR_1123: "error1123", // 14:
    ERROR_1124: "error1124", // 15:
    ERROR_1125: "error1125", // 16:
    ERROR_1126: "error1126",
    ERROR_1127: "error1127",
    ERROR_1128: "error1128",
    ERROR_1129: "error1129",
    ERROR_1130: "error1130",
    ERROR_1131: "error1131",
    ERROR_1132: "error1132",
    ERROR_1133: "error1133",
    ERROR_1134: "error1134",
    ERROR_1135: "error1135",
    ERROR_1136: "error1136",
    ERROR_1137: "error1137",
    ERROR_1138: "error1138",
    ERROR_1139: "error1139",
    ERROR_1140: "error1140",
    ERROR_1141: "error1141",
    ERROR_1142: "error1142",
    ERROR_1143: "error1143",
    ERROR_1144: "error1144",
    ERROR_1145: "error1145",
    ERROR_1146: "error1146",
    ERROR_1147: "error1147",
    ERROR_1148: "error1148",
    ERROR_1149: "error1149",
    ERROR_1150: "error1150",
    ERROR_1151: "error1151",
    ERROR_1152: "error1152",
    ERROR_1153: "error1153",
    ERROR_1154: "error1154",
    ERROR_1155: "error1155",
    ERROR_1156: "error1156",
    ERROR_1157: "error1157",
    ERROR_1158: "error1158",
    ERROR_1159: "error1159",
    ERROR_1160: "error1160",
    ERROR_1161: "error1161",
    ERROR_1162: "error1162",
    ERROR_1163: "error1163",
    ERROR_1164: "error1164",
    ERROR_1165: "error1165",
    ERROR_1166: "error1166",
    ERROR_1167: "error1167",
    ERROR_1168: "error1168",
    ERROR_1169: "error1169",
    ERROR_1170: "error1170",
    ERROR_1171: "error1171",
    ERROR_1172: "error1172",
    ERROR_1173: "error1173",
    ERROR_1174: "error1174",
    ERROR_1175: "error1175",
    ERROR_1176: "error1176",
    ERROR_1177: "error1177",
    ERROR_1178: "error1178",
    ERROR_1179: "error1179",
    ERROR_1180: "error1180",
    ERROR_1181: "error1181",
    ERROR_1182: "error1182",
    ERROR_1183: "error1183",
    ERROR_1184: "error1184",
    ERROR_1185: "error1185",
    ERROR_1186: "error1186",
    ERROR_1187: "error1187",
    ERROR_1188: "error1188",
    ERROR_1189: "error1189",
    ERROR_1190: "error1190",
    ERROR_1191: "error1191",
    ERROR_1192: "error1192",
    ERROR_1193: "error1193",
    ERROR_1194: "error1194",
    ERROR_1195: "error1195",
    ERROR_1196: "error1196",
    ERROR_1197: "error1197",
    ERROR_1198: "error1198",
    ERROR_1199: "error1199",
    ERROR_1201: "error1201",
    ERROR_1202: "error1202",
    ERROR_1203: "error1203",
    ERROR_1204: "error1204",
    ERROR_1205: "error1205",
    ERROR_1206: "error1206",
    ERROR_1207: "error1207",
    ERROR_1208: "error1208",
    ERROR_1209: "error1209",
    ERROR_1210: "error1210",
    ERROR_1211: "error1211",
    ERROR_1212: "error1212",
    ERROR_1213: "error1213",
    ERROR_1214: "error1214",
    ERROR_1215: "error1215",
    ERROR_1216: "error1216",
    ERROR_1217: "error1217",
    ERROR_1218: "error1218",
    ERROR_1219: "error1219",
    ERROR_1220: "error1220",
    ERROR_1221: "error1221",
    ERROR_1222: "error1222",
    ERROR_1223: "error1223",
    ERROR_1224: "error1224",
    ERROR_1225: "error1225",
    ERROR_1226: "error1226",
    ERROR_1227: "error1227",
    ERROR_1228: "error1228",
    ERROR_1229: "error1229",
    ERROR_1230: "error1230",
    ERROR_1231: "error1231",
    ERROR_1232: "error1232",
    ERROR_1233: "error1233",
    ERROR_1234: "error1234",
    ERROR_1235: "error1235",
    ERROR_1236: "error1236",
    ERROR_1237: "error1237",
    ERROR_1238: "error1238",
    ERROR_1239: "error1239",
    ERROR_1240: "error1240",
    ERROR_1241: "error1241",
    ERROR_1242: "error1242",
    ERROR_1243: "error1243",
    ERROR_1244: "error1244",
    ERROR_1245: "error1245",
    ERROR_1246: "error1246",
    ERROR_1247: "error1247",
    ERROR_1248: "error1248",
    ERROR_1249: "error1249",
    ERROR_1250: "error1250",
    ERROR_1251: "error1251",
    ERROR_1252: "error1252",
    ERROR_1253: "error1253",
    ERROR_1254: "error1254",
    ERROR_1255: "error1255",
    ERROR_1256: "error1256",
    ERROR_1257: "error1257",
    ERROR_1258: "error1258",
    ERROR_1259: "error1259",
    ERROR_1260: "error1260",
    ERROR_1261: "error1261",
    ERROR_1262: "error1262",
    ERROR_1263: "error1263",
    ERROR_1264: "error1264",
    ERROR_1265: "error1265",
    ERROR_1266: "error1266",
    ERROR_1267: "error1267",
    ERROR_1268: "error1268",
    ERROR_1269: "error1269",
    ERROR_1270: "error1270",
    ERROR_1271: "error1271",
    ERROR_1272: "error1272",
    ERROR_1273: "error1273",
    ERROR_1274: "error1274",
// Rechnung
    ERROR_STORNIERUNG_NICHT_MOEGLICH: "ERROR_STORNIERUNG_NICHT_MOEGLICH",
    ERROR_1276: "error1276",
    ERROR_1277: "error1277",
    ERROR_1278: "error1278",
    ERROR_1279: "error1279",
    ERROR_1280: "error1280",
    ERROR_1281: "error1281",
    ERROR_1282: "error1282",
    ERROR_1283: "error1283",
    ERROR_1284: "error1284",
    ERROR_1285: "error1285",
    ERROR_1286: "error1286",
    ERROR_1287: "error1287",
    ERROR_1288: "error1288",
    ERROR_1289: "error1289",
    ERROR_1290: "error1290",
    ERROR_1291: "error1291",
    ERROR_1292: "error1292",
    ERROR_1293: "error1293",
    ERROR_1294: "error1294",
    ERROR_1295: "error1295",
    ERROR_1296: "error1296",
    ERROR_1297: "error1297",
    ERROR_1298: "error1298",
    ERROR_1299: "error1299",

// Entity
    ERROR_ENTITY_TRANSACTION: "ERROR_ENTITY_TRANSACTION",

    ERROR_1301: "error1301", // Reservierung der Position: Lieferdatum liegt vor Tagesdatum

    ERROR_ANLAGE_TRANSAKTION: "ERROR_ANLAGE_TRANSAKTION",
    ERROR_AUFTRAGSNUMMER: "ERROR_AUFTRAGSNUMMER",
    ERROR_AUFTRAGSJAHR: "ERROR_AUFTRAGSJAHR",
    ERROR_MATERIALBEWEGUNGSKENNZEICHNEN: "ERROR_MATERIALBEWEGUNGSKENNZEICHNEN",
    ERROR_LAGER: "ERROR_LAGER",
    ERROR_RESERVIERUNGSMENGE: "ERROR_RESERVIERUNGSMENGE",
    ERROR_ANZAHL_RESERVIERUNGSPOSITIONEN: "ERROR_ANZAHL_RESERVIERUNGSPOSITIONEN",

    ERROR_1303: "error1303", // Reservierung der Position: Auftragsmenge fehlt
    ERROR_1304: "error1304", // Reservierung der Position: Lieferdatum fehlt
    ERROR_1305: "error1305", // Reservierung der Position: Kein Bestand
    ERROR_1306: "error1306", // Reservierung der Position: Auftragsmenge fehlt
    ERROR_1307: "error1307", // Reservierung der Position: Auftragsmenge fehlt
    ERROR_1308: "error1308", // Reservierung der Position: Auftragsmenge fehlt

    ERROR_1401: "error1401", // Abrechnungszeitraum fehlerhaft
    ERROR_1402: "error1402", // Startdatum fehlerhaft
    ERROR_1403: "error1403", // Endedatum fehlerhaft
    ERROR_1404: "error1404", //
    ERROR_1405: "error1405", //

    ERROR_ResultsetSizeMatching: "error1501", // Fehler beim Laden eines Auftrags

    ERROR_1601: "error1601", // Reservierung: Kein Standardlager im Artikelstamm zugewiesen; automatische Lagerortbestimmung nicht zugelassen
    ERROR_SELECT_NOT_POSSIBLE: "error1602", // Reservierung: Verfügbare Menge ist kleiner als angeforderte Menge
    ERROR_1603: "error1603", // Reservierung: Kein Bestand
    ERROR_1604: "error1604", // Reservierung:
    ERROR_1605: "error1605",

    ERROR_AUFTRAGSDATUM_LEER: "ERROR_AUFTRAGSDATUM_LEER",

//---------------------------------------------------------------------------------------------
// Allgemein
//---------------------------------------------------------------------------------------------
    ERROR_SERVICES_NOT_AVAILABLE: "ERROR_SERVICES_NOT_AVAILABLE",

//---------------------------------------------------------------------------------------------
// numberranges-service
//---------------------------------------------------------------------------------------------
    ERROR_NUMBERRANGES_SERVICE_NOT_AVAILABLE: "ERROR_NUMBERRANGES_SERVICE_NOT_AVAILABLE",

//---------------------------------------------------------------------------------------------
// item-service
//---------------------------------------------------------------------------------------------
    ERROR_ITEMSERVICE_NOT_AVAILABLE: "ERROR_ITEMSERVICE_NOT_AVAILABLE",
    ERROR_ARTICLE_ABRUF: "ERROR_ARTICLE_ABRUF",
    ERROR_ITEMSERVICE_ITEM_NOT_AVAILABLE: "ERROR_ITEMSERVICE_ITEM_NOT_AVAILABLE",

//---------------------------------------------------------------------------------------------
// UI
//---------------------------------------------------------------------------------------------
    ERROR_UI_TABLE_ROW: "ERROR_UI_TABLE_ROW",

//---------------------------------------------------------------------------------------------
// reservation-service
//---------------------------------------------------------------------------------------------
    ERROR_RESERVATION_SERVICE_NOT_AVAILABLE: "ERROR_RESERVATION_SERVICE_NOT_AVAILABLE",
    ERROR_RESERVATION_KEINBESTAND: "ERROR_RESERVATION_KEINBESTAND",
    ERROR_AUSLIEFERUNG_ABRECHNUNGSART: "ERROR_AUSLIEFERUNG_ABRECHNUNGSART",

//---------------------------------------------------------------------------------------------
// transaction-service
//---------------------------------------------------------------------------------------------
    ERROR_TRANSACTION_SERVICE_NOT_AVAILABLE: "ERROR_TRANSACTION_SERVICE_NOT_AVAILABLE",
    ERROR_TRANSACTION_SERVICE_FAILED: "ERROR_TRANSACTION_SERVICE_FAILED",
    ERROR_TRANSACTIONSERVICE_SETUP_TRANSACTION_FROM_ORDER: "ERROR_TRANSACTIONSERVICE_SETUP_TRANSACTION_FROM_ORDER",
    ERROR_TRANSACTIONSERVICE_SETUP_TRANSACTION_FROM_DELIVERYNOTE: "ERROR_TRANSACTIONSERVICE_SETUP_TRANSACTION_FROM_DELIVERYNOTE",

//---------------------------------------------------------------------------------------------
// order-service
//---------------------------------------------------------------------------------------------
    ERROR_ORDER_SERVICE_NOT_AVAILABLE: "ERROR_ORDER_SERVICE_NOT_AVAILABLE",
    ERROR_ORDERSERVICE_ROW_EXISTS: "ERROR_ORDERSERVICE_ROW_EXISTS",
    ERROR_ORDERSERVICE_NO_DATA: "ERROR_ORDERSERVICE_NO_DATA",

    ERROR_ORDER_AUFTRAGSNUMMER: "ERROR_ORDER_AUFTRAGSNUMMER",
    ERROR_ORDER_AUFTRAGSJAHR: "ERROR_ORDER_AUFTRAGSJAHR",
    ERROR_ORDER_VORGANGSART: "ERROR_ORDER_VORGANGSART",
    ERROR_ORDER_AUFTRAGSART: "ERROR_ORDER_AUFTRAGSART",
    ERROR_ORDER_KUNDENNUMMER: "ERROR_ORDER_KUNDENNUMMER",
    ERROR_ORDER_VERTRIEBSBEREICH: "ERROR_ORDER_VERTRIEBSBEREICH",

    ERROR_ORDER_ABRECHNUNGSART: "ERROR_ORDER_ABRECHNUNGSART",
    ERROR_ORDER_ZEILE: "ERROR_ORDER_ZEILE",
    ERROR_ORDER_ARTIKELNUMMER: "ERROR_ORDER_ARTIKELNUMMER",
    ERROR_ORDER_ARTIKELBEZEICHNUNG: "ERROR_ORDER_ARTIKELBEZEICHNUNG",
    ERROR_ORDER_LIEFERDATUM_VON: "ERROR_ORDER_LIEFERDATUM_VON",
    ERROR_ORDER_LIEFERDATUM_BIS: "ERROR_ORDER_LIEFERDATUM_BIS",
    ERROR_ORDER_BESTELLMENGE: "ERROR_ORDER_BESTELLMENGE",
    ERROR_ORDER_BESTELLEINHEIT: "ERROR_ORDER_BESTELLEINHEIT",
    ERROR_ORDER_AUFTRAGSMENGE: "ERROR_ORDER_AUFTRAGSMENGE",
    ERROR_ORDER_AUFTRAGSEINHEIT: "ERROR_ORDER_AUFTRAGSEINHEIT",
    ERROR_ORDER_CHARGE: "ERROR_ORDER_CHARGE",
    ERROR_ORDER_PREISGRUPPE: "ERROR_ORDER_PREISGRUPPE",
    ERROR_ORDER_WAEHRUNG: "ERROR_ORDER_WAEHRUNG",
    ERROR_ORDER_PREISTYP: "ERROR_ORDER_PREISTYP",
    ERROR_ORDER_RABATTSATZ: "ERROR_ORDER_RABATTSATZ",
    ERROR_ORDER_EINZELPREIS: "ERROR_ORDER_EINZELPREIS",
    ERROR_ORDER_GESAMTPREIS: "ERROR_ORDER_GESAMTPREIS",
    ERROR_ORDER_RESERVIERUNGSAUTOMATIK: "ERROR_ORDER_RESERVIERUNGSAUTOMATIK",
    ERROR_ORDER_RESERVIERUNG_STATUS: "ERROR_ORDER_RESERVIERUNG_STATUS",
    ERROR_ORDER_CHARGENVERARBEITUNG_STATUS: "ERROR_ORDER_CHARGENVERARBEITUNG_STATUS",
    ERROR_ORDER_BESTANDSFUEHRUNG_STATUS: "ERROR_ORDER_BESTANDSFUEHRUNG_STATUSRE",
    ERROR_ORDER_LAGER: "ERROR_ORDER_LAGER",
    ERROR_ORDER_STELLPLATZ: "ERROR_ORDER_STELLPLATZ",
    ERROR_ORDER_EBENE: "ERROR_ORDER_EBENE",
    ERROR_ORDER_FACH: "ERROR_ORDER_FACH",

//---------------------------------------------------------------------------------------------
// GSDATABASEMANAGEMENTSERVICE
//---------------------------------------------------------------------------------------------
    ERROR_GSDATABASEMANAGEMENTSERVICE_NO_DATA_AVAILABLE: "ERROR_GSDATABASEMANAGEMENTSERVICE_NO_DATA_AVAILABLE",

//---------------------------------------------------------------------------------------------
// SPARQL
//---------------------------------------------------------------------------------------------
    ERROR_CONNECTION_NOT_AVAILABLE: "ERROR_CONNECTION_NOT_AVAILABLE",
    ERROR_REQUEST_NOT_AVAILABLE: "ERROR_REQUEST_NOT_AVAILABLE",

    ERROR_NAMESPACE_PREFIX: "ERROR_NAMESPACE_PREFIX",
    ERROR_NAMESPACE_LOCALNAME: "ERROR_NAMESPACE_LOCALNAME",
    ERROR_NAMESPACE_NAMESPACEURI: "ERROR_NAMESPACE_NAMESPACEURI",
    ERROR_NAMESPACE_KEY_EXISTS: "ERROR_NAMESPACE_KEY_EXISTS",
    ERROR_NAMESPACE_KEY_DOESNTEXIST: "ERROR_NAMESPACE_KEY_DOESNTEXIST",

    ERROR_TRIPLE_SUBJECT_PREFIX: "ERROR_TRIPLE_SUBJECT_PREFIX",
    ERROR_TRIPLE_SUBJECT_LOCALPART: "ERROR_TRIPLE_SUBJECT_LOCALPART",
    ERROR_TRIPLE_SUBJECT_NAMESPACEURI: "ERROR_TRIPLE_SUBJECT_NAMESPACEURI",

    ERROR_TRIPLE_PREDICATE_PREFIX: "ERROR_TRIPLE_PREDICATE_PREFIX",
    ERROR_TRIPLE_PREDICATE_LOCALPART: "ERROR_TRIPLE_PREDICATE_LOCALPART",
    ERROR_TRIPLE_PREDICATE_NAMESPACEURI: "ERROR_TRIPLE_PREDICATE_NAMESPACEURI",

    ERROR_TRIPLE_OBJECT_PREFIX: "ERROR_TRIPLE_OBJECT_PREFIX",
    ERROR_TRIPLE_OBJECT_LOCALPART: "ERROR_TRIPLE_OBJECT_LOCALPART",
    ERROR_TRIPLE_OBJECT_TYPEDEF: "ERROR_TRIPLE_OBJECT_TYPEDEF",
    ERROR_TRIPLE_OBJECT_NAMESPACEURI: "ERROR_TRIPLE_OBJECT_NAMESPACEURI",
    ERROR_TRIPLE_OBJECT_TYPE: "ERROR_TRIPLE_OBJECT_TYPE",
    ERROR_TRIPLE_OBJECT_XSDDATAYPE: "ERROR_TRIPLE_OBJECT_XSDDATAYPE",
    ERROR_TRIPLE_OBJECT_LINKTYPE: "ERROR_TRIPLE_OBJECT_LINKTYPE",

    ERROR_CSVIMPORT_PROPERTY_TYPE: "ERROR_CSVIMPORT_PROPERTY_TYPE",
    ERROR_CSVIMPORT_MISSING_SUPERCLASS: "ERROR_CSVIMPORT_MISSING_SUPERCLASS",
    ERROR_CSVIMPORT_MISSING_CLASS: "ERROR_CSVIMPORT_MISSING_CLASS",
    ERROR_CSVIMPORT_MISSING_Property: "ERROR_CSVIMPORT_MISSING_Property",
    ERROR_CSVIMPORT_CLASS_ALREADY_EXISTS: "ERROR_CSVIMPORT_CLASS_ALREADY_EXISTS",

//---------------------------------------------------------------------------------------------
// CSVImport
//---------------------------------------------------------------------------------------------
    SUCCESS_CSVIMPORT: "SUCCESS_CSVIMPORT",
    ERROR_CSVIMPORT: "ERROR_CSVIMPORT",
    ERROR_CSVIMPORT_CLASSNAME: "ERROR_CSVIMPORT_CLASSNAME",
    ERROR_CSVIMPORT_SUPERCLASS: "ERROR_CSVIMPORT_SUPERCLASS",
    ERROR_CSVIMPORT_DOMAIN: "ERROR_CSVIMPORT_DOMAIN",
    ERROR_CSVIMPORT_URI: "ERROR_CSVIMPORT_URI",
    ERROR_CSVIMPORT_SUBJECT: "ERROR_CSVIMPORT_SUBJECT",
    ERROR_CSVIMPORT_DATAPROPERTIES: "ERROR_CSVIMPORT_DATAPROPERTIES",
    ERROR_CSVIMPORT_HEADERLIST: "ERROR_CSVIMPORT_HEADERLIST",
    ERROR_CSVIMPORT_DATA: "ERROR_CSVIMPORT_DATA",
    ERROR_CSVIMPORT_MISSING_PREFIX_FOR_CLASS: "ERROR_CSVIMPORT_MISSING_PREFIX_FOR_CLASS",
    ERROR_CSVIMPORT_MISSING_BUILDINFOLIST: "ERROR_CSVIMPORT_MISSING_BUILDINFOLIST",
    ERROR_CSVIMPORT_MISSING_DATALINKTYPE: "ERROR_CSVIMPORT_MISSING_DATALINKTYPE",
    ERROR_CSVIMPORT_MISSING_DATA: "ERROR_CSVIMPORT_MISSING_DATA",
    ERROR_CSVIMPORT_MISSING_DATALINKINFO: "ERROR_CSVIMPORT_MISSING_DATALINKINFO",
    ERROR_CSVIMPORT_BUILDINFO: "ERROR_CSVIMPORT_BUILDINFO",
    ERROR_CSVIMPORT_DATAEXISTS: "ERROR_CSVIMPORT_DATAEXISTS",
    ERROR_CSVIMPORT_SNAPSHOT: "ERROR_CSVIMPORT_SNAPSHOT",


    WARNING_CSVIMPORT_COLUMNCOUNT: "WARNING_CSVIMPORT_COLUMNCOUNT",
    WARNING_CSVIMPORT_LOCALPART_EMPTY: "WARNING_CSVIMPORT_LOCALPART_EMPTY",

//---------------------------------------------------------------------------------------------
// Linked Data Analyzer
//---------------------------------------------------------------------------------------------
    ERROR_LDASERVICE_DATALINK: "ERROR_LDASERVICE_DATALINK",
    ERROR_LDASERVICE_DATALINK_DEFINITION: "ERROR_LDASERVICE_DATALINK_DEFINITION",

//---------------------------------------------------------------------------------------------
// Delivery-Service
//---------------------------------------------------------------------------------------------
    ERROR_DELIVERYSERVICE_NOT_AVAILABLE: "ERROR_PARAMETER_NOT_AVAILABLE",
    ERROR_DELIVERYSERVICE_ROW_EXISTS: "ERROR_DELIVERYSERVICE_ROW_EXISTS",
    ERROR_DELIVERYSERVICE_NO_DATA: "ERROR_DELIVERYSERVICE_NO_DATA",
    ERROR_DELIVERYSERVICE_ABRECHNUNG_NICHT_MOEGLICH: "ERROR_DELIVERYSERVICE_ABRECHNUNG_NICHT_MOEGLICH",

//---------------------------------------------------------------------------------------------
// Parameter-Service
//---------------------------------------------------------------------------------------------
    ERROR_PARAMETERSERVICE_NOT_AVAILABLE: "ERROR_PARAMETERSERVICE_NOT_AVAILABLE",
    ERROR_PARAMETERSERVICE_ROW_EXISTS: "ERROR_PARAMETERSERVICE_ROW_EXISTS",
    ERROR_PARAMETERSERVICE_NO_DATA: "ERROR_PARAMETERSERVICE_NO_DATA",
    ERROR_PARAM_SERVICE_MULTIPLE_INSTANCES: "ERROR_PARAMETERSERVICE_MULTIPLE_INSTANCES"
}