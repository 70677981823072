// import ClientOAuth2 from "client-oauth2";
import Cookies from 'js-cookie'
import axios from 'axios'
import {APIURL} from "../../Views/Routes";


export let peerconnectionsStreams = null

export let notificationObject = null


export const getPeerconnections = () => {
    return peerconnectionsStreams;
}

export const setPeerConnection = (peer) => {
    peerconnectionsStreams = peer
}

export const setNotificationObject = (noti) => {
    notificationObject = noti
}


export const getSession = () => {
    const jwt = Cookies.get('__session')
    let session
    try {
        if (jwt) {
            session = JSON.parse(window.atob(jwt))
            if (Date.parse(session.expiresIn) < Date.now()) {
                // refreshtoken(session)
            }
        }
    } catch (error) {
        console.log(error)
    }
    return session
}

export function logout() {

    const isflutter = typeof window.flutter_inappwebview !== 'undefined'

    if (isflutter) {
        if (!window.flutter_inappwebview.callHandler) {
            return window.flutter_inappwebview._callHandler('logout').then(x => {
                    Cookies.remove("__session")
                    Cookies.remove("session")
                    Cookies.remove("signature")
                }
            )

        } else {
            return window.flutter_inappwebview.callHandler('logout').then(x => {
                    Cookies.remove("__session")
                    Cookies.remove("session")
                    Cookies.remove("signature")
                }
            )

        }
    } else {

        Cookies.remove("__session")
        Cookies.remove("session")
        Cookies.remove("signature")
    }
}


export function isBase64(string) {
    var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    return base64regex.test(string);
}


export function sessionValid() {
    return !!Cookies.get("session");
}

export function setSessionInvalid() {
    Cookies.remove("session");
}

export function getCookies() {
    return Cookies.get("session") + "." + Cookies.get("signature")
}


export const gsHeader = (showloading = true) => {
    return {
        headers: {
            // "Authorization": session(),
            'Content-Type': 'application/json'
        }
        , showloading: showloading
    }
}

export const gsHeaderData = (data, showloading = true) => {

    return {
        headers: {
            // "Authorization": "Bearer " + getSession().accessToken,
            'Content-Type': 'application/json'
        },
        data
        , showloading: showloading
    }
}

export const fetchGraphQLQuery = async (query, variables) => {

    const data = await fetch(
        APIURL().graphqlQuery,
        {
            method: 'POST',
            headers: {
                // Accept: 'application/json',
                'Content-Type': 'application/json',
                // "Authorization": "Bearer " + getSession().accessToken,
            },
            body: JSON.stringify({
                query: query,
                variables: variables
            }),
            credentials: 'include',

        },
    );


    return data.json()


}

export const axiosGraphQLQuery = async (query, variables, showloading = true) => {
    return await axios.post(APIURL().graphqlQuery, {query: query, variables: variables}, {
        headers: {
            // "Authorization": session(),
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        showloading: showloading
    })
}

export const loadGSReports = async () => {
    const data = await fetch(
        APIURL().graphqlQuery,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getSession().accessToken,
            },
            body: JSON.stringify({
                query: `{
                         report{
                          name
                          report
                            }
                        }
                        `
            }),
            credentials: 'include',
            // credentials: 'same-origin',
        },
    );

    return data.json();
}

export const initLevel0And1 = async () => {

    await axiosGraphQLQuery(`
            mutation{
                createLevel0
            }`)


}

export function passwordCredentials(username, password, nextStep, setError) {

    return axios.post(APIURL().login, {username: username, password: password}, {showloading: true})
        .then(x => {

                axios.get(APIURL().userInfo, {
                    withCredentials: true
                })
                    .then(x => {
                        var user = {}
                        user.username = x.data.gsuser.username
                        user.account = x.data.account.accountName
                        user.email = x.data.gsuser.email
                        user.dataset = x.data.mandant.mandantName
                        user.levels = x.data.gsuserLevels.map(x => x.level)
                        setAuthCookie(user);
                    }).then(x => nextStep())
                    .catch(err => {
                        Cookies.remove("__session")
                    })
            }
        ).catch(err => {
            setError(true)
        })
}


export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}


function setAuthCookie(user) {
    Cookies.remove("__session")
    Cookies.set('__session', btoa(
        JSON.stringify({
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
            expiresIn: user.expires,
            username: user.username,
            account: user.account,
            levels: user.levels,
            dataset: user.dataset
        })),)
}


export function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

export function buildAddRequest(name, sri, columns, data) {

    var escapeSRI = sri.replace("$", "\$")
    var header = "[" + columns.map(x => `"${x.name}"`).join(",") + "]"
    var datalinks = columns
        .map(x => `{alias:"${x.name}",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:${x.datatype.toUpperCase()}}`)
        .join("\n")

    var data = data.map(x => "[" + Object.keys(x).map(y => `"${x[y]}"`) + "]").join("\n")
    var stmnt = `
        mutation{
          addDTable(
            dtablename:"${name}",
            superDTable:DTABLE,
            sriBuildInfo:"${escapeSRI}",
            dataLinks:[
             ${datalinks}
            ],
            data:[
                ${header}
                ${data}
            ]
          )
        }
    `
    return axiosGraphQLQuery(stmnt, null, false)
}

export function buildAddStatment(name, sri, columns, data) {

    var escapeSRI = sri.replace("$", "\$")
    var header = "[" + columns.map(x => `"${x.name}"`).join(",") + "]"
    var datalinks = columns
        .map(x => `{alias:"${x.name}",locale:DE,superPropertyURI:DYNAMIC_DATALINK,DataType:${x.datatype.toUpperCase()}}`)
        .join("\n")
    var data = data.map(x => {
        return "[" + Object.keys(x).map(y => `"${x[y]}"`) + "]"
    }).join("\n")

    var stmnt = `
        mutation{
          addDTable(
            dtablename:"${name}",
            superDTable:DTABLE,
            sriBuildInfo:"${escapeSRI}",
            dataLinks:[
             ${datalinks}
            ],
            data:[
                ${header}
                ${data}
            ]
          )
        }
    `

    return stmnt
}


export default function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}