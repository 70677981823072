import React from 'react'
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";


export default function ConfirmButtonDialog({title, text, onConfirm, onCancel}) {


    return <div>

        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
            >
                {text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={onCancel}>Abbrechen</Button>
            <Button variant="contained" color="secondary" onClick={onConfirm}>Bestätigen</Button>
        </DialogActions>
    </div>

}
