import React, {useEffect, useState} from 'react'
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import UserNamePasswordLogin from "../Checkout/UsernamePasswordLogin";
import {getSession, sessionValid} from "../Auth/AuthHandler";
import {useTheme} from "@mui/material";
import axios from "axios";

export default function ErrorMessage(props) {
    const [text, setText] = useState('')

    useEffect(() => {
        setText(props.text === undefined ? '' : props.text)
    }, [])

    return <Alert
        severity="error"> {text !== '' ? text : "Da ist etwas schief gegangen! Bitte probieren Sie es erneut!"}</Alert>
}

export function AlertDialog(props) {
    const {error, setError, errorMessage, setErrorMessage, title} = props

    const handleClose = () => {
        setError(false)
        setErrorMessage("")
    };

    return (
        <div>
            <Dialog
                open={error}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{!title ? "Es ist ein Fehler aufgetreten" : title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorMessage === "" ?
                            "Da ist etwas schief gegangen! Bitte probieren Sie es erneut!" :
                            errorMessage
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function LoginDialog(props) {
    const {expired, setExpired, pendingRequests} = props
    const theme = useTheme()
    const handleClose = () => {
        setExpired(false)

    };

    useEffect(() => {

        if (!getSession() || sessionValid()) {
            setExpired(false)
        }

    }, [])


    return <div>
        <Dialog
            style={{
                // border: "1px solid " + theme.palette.primary.main
            }}
            PaperProps={{
                style: {
                    border: "2px solid " + theme.palette.primary.main
                },
                elevation: 3

            }}
            BackdropProps={
                {
                    style: {
                        backdropFilter: "blur(3px)",
                        backgroundColor: 'rgba(0,0,30,0.4)'
                    }
                }
            }
            open={expired}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                {/*<DialogContentText id="alert-dialog-description">*/}
                <UserNamePasswordLogin nextStep={handleClose}
                                       headerText={getSession()?.username + ", Sind Sie noch da?"}
                                       session={true}/>
                {/*</DialogContentText>*/}
            </DialogContent>
            {/*<DialogActions>*/}
            {/*    <Button onClick={handleClose} color="primary" variant="contained" autoFocus>*/}
            {/*        OK*/}
            {/*    </Button>*/}
            {/*</DialogActions>*/}
        </Dialog>
    </div>

}