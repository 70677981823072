import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ConfirmContextProvider} from "./components/dialogs/ConfirmContextProvider";
import {SnackbarProvider} from "notistack";


const initalState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
}

ReactDOM.render(
    // <React.StrictMode>
    <ConfirmContextProvider>
        <SnackbarProvider maxSnack={5} style={{marginRight: -20}}>
            <App/>
        </SnackbarProvider>
    </ConfirmContextProvider>
    // </React.StrictMode>
    , document.getElementById('root'));




