import React from "react";
import {Redirect, Route} from "react-router-dom";
import {getSession} from "../Auth/AuthHandler";

export default function PrivateRoute(props) {
    const {content} = props
    var clonedElementWithMoreProps = React.cloneElement(
        content,
        {...props}
    );


    return (
        <Route exact
               render={props =>
                   getSession() ? (
                       clonedElementWithMoreProps
                   ) : (
                       <Redirect
                           to={{
                               pathname: "/login",
                               search: `?url=${props.location.pathname}`,
                               state: {from: props.location}
                           }}
                       />
                   )
               }
        />
    );
}