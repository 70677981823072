import useConfirm from "./useConfirm";
import {DialogContent, Paper, useTheme} from "@mui/material";
import UserNamePasswordLogin from "../Checkout/UsernamePasswordLogin";
import {createPortal} from "react-dom";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const LoadingDialog = (props) => {

    const {loading} = props


    const portalElement = document.getElementById('portal');
    const component = loading ? (
        <div className="portal-overlay">
            <div className="confirm-dialog">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <CircularProgress color="primary"/>
                </Box>

            </div>
        </div>
    ) : null;

    return createPortal(component, portalElement);
};
export default LoadingDialog;